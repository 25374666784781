import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700;900&display=swap');
  :root {
    --flips: 540deg;
    --coin-color: #f7941e;
    --coin-border: #f9a43c;
    --coin-shadow: #df841b;
    --background-color: #2c2c2c;
    --text-color: #f5f5f5;
    --accent-color: #4CAF50;
  }
  body {
    background: var(--background-color);
    margin: 0;
    padding: 0;
    min-height: 100vh;
    font-family: 'Raleway', sans-serif;
    color: var(--text-color);
    line-height: 1.6;
  }
`;

const flip = keyframes`
  0% { transform: rotateY(0) rotateX(0deg) scale(1); }
  10% { transform: rotateY(45deg) rotateX(calc(var(--flips) / 3)) scale(1.6); }
  60% { transform: rotateY(-30deg) rotateX(calc(var(--flips) / 1.5)) scale(2); }
  100% { transform: rotateY(0) rotateX(var(--flips)) scale(1); }
`;

const lines = keyframes`
  40% { opacity: 1; background-position: -120px 0; }
  70% { opacity: 1; background-position: 75px 0; }
  100% { opacity: 1; background-position: 150px 0; }
`;

const ShareIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <circle cx="18" cy="5" r="3"></circle>
    <circle cx="6" cy="12" r="3"></circle>
    <circle cx="18" cy="19" r="3"></circle>
    <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
    <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
  </svg>
);

const CoinFlip: React.FC = () => {
  const [flipping, setFlipping] = useState(false);
  const [flips, setFlips] = useState('540deg');
  const [buttonLabel, setButtonLabel] = useState("Flip the coin!");
  const [activeSection, setActiveSection] = useState('flip-a-coin');
  const [showShareButtons, setShowShareButtons] = useState(false);
  const [openPopup, setOpenPopup] = useState<string | null>(null);
  const popupRef = useRef<HTMLDivElement | null>(null);

  const handleClick = useCallback(() => {
    if (flipping) return;
    setFlipping(true);

    const winner = Math.random() > 0.5 ? '900deg' : '720deg';
    setFlips(winner);

    setTimeout(() => {
      const coin = document.querySelector('.coin') as HTMLElement;
      if (coin) {
        coin.classList.remove('anim');
        void coin.offsetWidth;
        coin.classList.add('anim');
      }
    }, 0);

    setTimeout(() => {
      setFlipping(false);
      setButtonLabel("Flip again!");
    }, 1100);
  }, [flipping]);

  useEffect(() => {
    document.documentElement.style.setProperty('--flips', flips);
  }, [flips]);

  const adjustColor = (color: string, amount: number) => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
  };

  const handleColorChange = (color: string) => {
    document.documentElement.style.setProperty('--coin-color', color);
    document.documentElement.style.setProperty('--coin-border', adjustColor(color, 20));
    document.documentElement.style.setProperty('--coin-shadow', adjustColor(color, -20));
    document.documentElement.style.setProperty('--background-color', adjustColor(color, -40));
  };

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setActiveSection(sectionId);
      window.history.pushState(null, '', `/#${sectionId}`);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
            if (entry.target.id !== 'flip-a-coin') {
              window.history.pushState(null, '', `/#${entry.target.id}`);
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    const sections = document.querySelectorAll('section');
    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  const handleShare = (platform: 'facebook' | 'twitter') => {
    const url = encodeURIComponent(window.location.href);
    const text = encodeURIComponent('Flip a coin and let fate have a laugh!');
    let shareUrl = '';

    if (platform === 'facebook') {
      shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    } else if (platform === 'twitter') {
      shareUrl = `https://twitter.com/intent/tweet?url=${url}&text=${text}`;
    }

    window.open(shareUrl, '_blank');
  };

  const openPolicy = (policy: string) => {
    setOpenPopup(policy);
    window.history.pushState(null, '', `/${policy.toLowerCase()}`);
  };

  const closePopup = () => {
    setOpenPopup(null);
    window.history.pushState(null, '', '/');
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        closePopup();
      }
    };

    if (openPopup) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openPopup]);

  return (
    <>
      <GlobalStyle />
      <World>
        <div id="flip-a-coin"></div>
        <Floor>
          {[...Array(12)].map((_, i) => (
            <Line key={i} $rotate={i * 30} $scale={i % 2 !== 0} />
          ))}
        </Floor>
        <Coin className="coin" onClick={handleClick}>
          <Edge>
            {[...Array(16)].map((_, i) => (
              <Segment key={i} $rotate={i * 11.25} />
            ))}
          </Edge>
        </Coin>
        <InfoSection>
          <FlipAgainButton onClick={handleClick}>{buttonLabel}</FlipAgainButton>
          <ColorSelection>
            <ColorBox color="#f7941e" onClick={() => handleColorChange('#f7941e')} />
            <ColorBox color="#4CAF50" onClick={() => handleColorChange('#4CAF50')} />
            <ColorBox color="#9C27B0" onClick={() => handleColorChange('#9C27B0')} />
            <ColorBox color="#F44336" onClick={() => handleColorChange('#F44336')} />
            <ColorBox color="#2196F3" onClick={() => handleColorChange('#2196F3')} />
          </ColorSelection>
          <ShareButtonsContainer show={showShareButtons}>
            <ShareButton platform="facebook" onClick={() => handleShare('facebook')}>
              Share on Facebook
            </ShareButton>
            <ShareButton platform="twitter" onClick={() => handleShare('twitter')}>
              Share on Twitter
            </ShareButton>
          </ShareButtonsContainer>
          <ShareIconButton onClick={() => setShowShareButtons(!showShareButtons)}>
            <ShareIcon />
          </ShareIconButton>
          <Navigation>
            <NavLink onClick={() => scrollToSection('intro')}>Coin Flip Toss</NavLink>
            <NavLink onClick={() => scrollToSection('history')}>The Epic Tale of Coin Flipping</NavLink>
            <NavLink onClick={() => scrollToSection('psychology')}>Mind Games with Coins</NavLink>
            <NavLink onClick={() => scrollToSection('famous-flips')}>Legendary Coin Flips</NavLink>
            <NavLink onClick={() => scrollToSection('faq')}>Coin Flip FAQs</NavLink>
          </Navigation>
        </InfoSection>
        <ContentSection>
          <Section id="intro">
            <Title>Flip and Coin</Title>
            <Paragraph>
              This lightning-fast, user-friendly tool generates truly random 50/50 outcomes. To play, just click or tap the coin. After your flip, check out your flip number! Customize your experience by clicking/tapping the color boxes to select your favorite color scheme. Go on, flip until your heart's content!
            </Paragraph>
            <Paragraph>Coin for coin toss is ideal for:</Paragraph>
            <List>
              <li>Deciding between two equally tempting options</li>
              <li>Engaging in a thrilling game of Heads or Tails with friends</li>
              <li>Making those pesky yes or no decisions</li>
              <li>Determining which team kicks off the game</li>
              <li>Settling disputes between two players</li>
              <li>Breaking ties with flair</li>
              <li>Choosing whether to do something daring or not</li>
              <li>Providing an alternative to Rock, Paper, Scissors</li>
              <li>Deciding which of two movies or shows to binge-watch</li>
              <li>Teaching kids and students about the wonders of probability</li>
              <li>Making fantasy league picks with style</li>
            </List>
            <BackToTopButton onClick={() => scrollToSection('flip-a-coin')}>Back to the Coin!</BackToTopButton>
          </Section>
          <Section id="history">
            <Title>The Epic Tale of Coin Flipping</Title>
            <Paragraph>
              Coins have been around since the 7th century BC, but the art of coin flipping dates back to ancient Rome. The Romans called it "navia aut caput," meaning "ship or head," because some coins had a ship on one side and the emperor's head on the other. Even Julius Caesar got in on the action in 49 BC, minting coins with his name. Back then, flips were used for serious decisions, like criminal cases, property disputes, and even marriage proposals. Talk about high stakes!
            </Paragraph>
            <BackToTopButton onClick={() => scrollToSection('flip-a-coin')}>Back to the Coin!</BackToTopButton>
          </Section>
          <Section id="psychology">
            <Title>Mind Games with Coins</Title>
            <Paragraph>
              When faced with tough choices, a coin flip can be your best friend. But if you find yourself disappointed with the result, maybe it's a sign you should follow your heart instead of the coin. Sometimes, the coin knows what you really want before you do!
            </Paragraph>
            <BackToTopButton onClick={() => scrollToSection('flip-a-coin')}>Back to the Coin!</BackToTopButton>
          </Section>
          <Section id="famous-flips">
            <Title>Legendary Coin Flips</Title>
            <Subtitle>The Naming of Portland, OR</Subtitle>
            <Paragraph>
              One of history's most famous flips happened in 1845 with a humble one-cent coin. Portland's founders, Francis Pettygrove and Asa Lovejoy, both wanted to name the area. They settled it with a best-of-three coin toss. Pettygrove won, naming the city Portland after his hometown in Maine. The coin, now known as the Portland Penny, made history!
            </Paragraph>
            <BackToTopButton onClick={() => scrollToSection('flip-a-coin')}>Back to the Coin!</BackToTopButton>
          </Section>
          <Section id="faq">
            <Title>Coin Flip FAQ</Title>
            <Subtitle>What is a coin toss?</Subtitle>
            <Paragraph>
              A coin toss, also known as a coin flip or "Heads or Tails," is a game where a coin is flicked into the air, spinning rapidly. While airborne, someone calls "Heads" or "Tails." When the coin lands, the side facing up determines the winner. It's a classic way to make decisions with a touch of suspense!
            </Paragraph>

            <Subtitle>Why is a coin toss used for decision-making?</Subtitle>
            <Paragraph>
              A coin toss is often used for decision-making because it is a simple, fair, and random method. It provides an unbiased 50/50 chance for either outcome, making it ideal for settling disputes or making choices when no other criteria are available.
            </Paragraph>

            <Subtitle>What are the odds of a coin landing on its edge?</Subtitle>
            <Paragraph>
              The odds of a coin landing on its edge are extremely low, typically less than 1 in 6,000. This is because coins are designed to have two flat sides, making it unlikely for them to balance on their thin edge.
            </Paragraph>

            <Subtitle>Can a coin toss be influenced?</Subtitle>
            <Paragraph>
              While a coin toss is generally considered random, factors such as the force of the flip, the height of the toss, and the surface it lands on can slightly influence the outcome. However, these factors are usually negligible in casual settings.
            </Paragraph>

            <Subtitle>What is the history of the coin toss?</Subtitle>
            <Paragraph>
              The coin toss has a long history, dating back to ancient Rome where it was known as "navia aut caput" (ship or head). It has been used throughout history for decision-making, including in sports, politics, and everyday life.
            </Paragraph>
            <BackToTopButton onClick={() => scrollToSection('flip-a-coin')}>Back to the Coin!</BackToTopButton>
          </Section>
        </ContentSection>
        <Footer>
          <FooterLink onClick={() => openPolicy('disclaimer')}>Disclaimer</FooterLink>
          <FooterLink onClick={() => openPolicy('privacy')}>Privacy Policy</FooterLink>
          <FooterLink onClick={() => openPolicy('cookie')}>Cookie Policy</FooterLink>
          <p>© 2024 Boyswithbeards. All Rights Reserved.</p>
        </Footer>
      </World>
      <Popup ref={popupRef} isOpen={openPopup === 'disclaimer'}>
        <PopupContent>
          <Title>Disclaimer</Title>
          <Paragraph>The information provided on this website is for general information purposes only. While we endeavour to keep such information up to date and correct, we make no representations or warranties of any kind, expressed or implied, about the completeness, accuracy, reliability, suitability, or availability of the information, or any other content appearing on this website for any purpose. Any reliance you place on such information is strictly at your own risk. In no event will the creators of this website nor any related third parties be liable for any loss or damage whatsoever arising from or in connection with the use of this website.</Paragraph>
          <CloseButton onClick={closePopup}>&times;</CloseButton>
        </PopupContent>
      </Popup>
      <Popup ref={popupRef} isOpen={openPopup === 'privacy'}>
        <PopupContent>
          <Title>Privacy Policy</Title>
          <Paragraph>Hey there! Thanks for considering Coin Flip Toss as your go-to source for all things humorous and artificial. We're excited to have you on board, let's talk about privacy.

          We know you're a savvy internet user and probably already know what a cookie is, but just in case: it's a tiny file that we store on your computer to remember your preferences and actions over time. Don't worry, it's not a real cookie (though we won't judge if you want to go grab a chocolate chip one while you read this).</Paragraph>
          <CloseButton onClick={closePopup}>&times;</CloseButton>
        </PopupContent>
      </Popup>
      <Popup ref={popupRef} isOpen={openPopup === 'cookie'}>
        <PopupContent>
          <Title>Cookie Policy</Title>
          <Paragraph>This website uses cookies to:
            We use cookies to identify you as a returning user, track your visits, and remember your custom display preferences. They also help us provide other handy features, like remembering if you've already given your consent to cookies (because who doesn't love a good consent form?).

            You can delete cookies that are already on your computer and set your browser to prevent them from being placed, but keep in mind that this may cause some services and functionalities to not work as smoothly. If you want to make changes to your cookie settings, check out the instructions on various browsers here.

            Third Party Advertising
            Now, let's talk about third-party advertising. We may have some advertising companies serving ads to you when you visit Coin Flip Toss. These companies may store information about your visits to our site and other websites to provide you with relevant advertisements about goods and services. They may use cookies and other identifiers to measure advertising effectiveness and gather information, but don't worry - this information is usually not personally identifiable unless you provide it to them directly.

            How To Opt Out Of Interest-Based Advertising
            These third-party advertising companies may also show you personalized ads based on your interactions and clicks on Coin Flip Toss, but they won't associate your interactions on unaffiliated sites with your identity. If you want to learn more about limiting the gathering of information by third-party ad networks, you can check out the website of the Network Advertising Initiative.

            If you want to opt out of participating in interest-based advertising networks, you can use the opt-out links provided by the Digital Advertising Alliance or the European Interactive Digital Advertising Alliance. You can also adjust your browser settings to block cookies from being placed.</Paragraph>
          <CloseButton onClick={closePopup}>&times;</CloseButton>
        </PopupContent>
      </Popup>
    </>
  );
};

const Title = styled.h2`
  font-size: 2rem;
  font-weight: 900;
  margin-bottom: 20px;
  color: var(--accent-color);
`;

const Subtitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 15px;
  color: var(--accent-color);
`;

const Paragraph = styled.p`
  font-size: 1rem;
  margin-bottom: 20px;
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 20px;
`;

const World = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 10px;
`;

const Floor = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
`;

const Line = styled.div<{ $rotate: number; $scale: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -3.125px;
  width: 150px;
  height: 6.25px;
  transform-origin: center left;
  border-radius: 6.25px;
  background: linear-gradient(90deg, white 20%, transparent 20%);
  background-repeat: no-repeat;
  opacity: 0;
  transform: ${props => `rotate(${props.$rotate}deg) ${props.$scale ? 'scale(1.1)' : ''}`};
  &.anim {
    animation: ${lines} 0.6s ease-out forwards;
    animation-delay: 0.65s;
  }
`;

const Coin = styled.div`
  position: relative;
  height: 300px;
  width: 300px;
  transform-style: preserve-3d;
  transform-origin: 50%;
  cursor: grab;
  margin-bottom: 20px;
  &.anim {    
    animation: ${flip} 1s linear forwards;
  }
  &:before, &:after {
    content: '';
    display: grid;
    place-items: center;
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background: var(--coin-color);
    border: 24px solid var(--coin-border);
    box-shadow: inset 0 0 0 6px var(--coin-shadow);
    font-size: 60px;
    font-family: 'Raleway', sans-serif;
    color: var(--coin-shadow);
    text-shadow: 3px 3px 0 var(--coin-shadow), -3px -3px 0 var(--coin-border);
  }
  &:before {
    transform: translateZ(25px);
    content: 'HEADS';
  }
  &:after {
    transform: translateZ(-25px) rotateY(180deg) rotateZ(180deg);
    content: 'TAILS';
  }
`;

const Edge = styled.div`
  transform: translateX(125px);
  transform-style: preserve-3d;
  backface-visibility: hidden;
`;

const Segment = styled.div<{ $rotate: number }>`
  height: 300px;
  width: 50px;
  position: absolute;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transform: ${props => `rotateY(90deg) rotateX(${props.$rotate}deg)`};
  &:before, &:after {
    content: '';
    display: block;
    height: 30px;
    width: 50px;
    position: absolute;
    transform: rotateX(84.375deg);
  }
  &:before {
    transform-origin: top center;
    background: repeating-linear-gradient(var(--coin-shadow) 0, var(--coin-shadow) 25%, var(--coin-color) 25%, var(--coin-color) 50%);
  }
  &:after {
    bottom: 0;
    transform-origin: center bottom;
    background: repeating-linear-gradient(var(--coin-color) 0, var(--coin-color) 25%, var(--coin-shadow) 25%, var(--coin-shadow) 50%);
  }
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const FlipAgainButton = styled.button`
  background-color: var(--accent-color);
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 25px;
  font-weight: bold;

  &:hover {
    transform: scale(1.05);
    transition: transform 0.2s ease-in-out;
  }
`;

const ColorSelection = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

const ColorBox = styled.div<{ color: string }>`
  width: 30px;
  height: 30px;
  background-color: ${props => props.color};
  margin: 0 5px;
  cursor: pointer;
  border-radius: 50%;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
`;

const ShareButtonsContainer = styled.div<{ show: boolean }>`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  overflow: hidden;
  height: ${props => props.show ? '40px' : '0'};
  transition: height 0.3s ease-in-out;
`;

const ShareButton = styled.button<{ platform: 'facebook' | 'twitter' }>`
  background-color: ${props => props.platform === 'facebook' ? '#3b5998' : '#1da1f2'};
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 20px;
  font-weight: bold;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const ShareIconButton = styled.button`
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  padding: 5px;
  margin-top: 10px;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

const Navigation = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
`;

const NavLink = styled.a`
  color: white;
  text-decoration: none;
  margin: 5px 10px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const ContentSection = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  color: white;
`;

const Section = styled.section`
  margin-bottom: 40px;
`;

const BackToTopButton = styled.button`
  background-color: var(--accent-color);
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 20px;

  &:hover {
    transform: scale(1.05);
    transition: transform 0.2s ease-in-out;
  }
`;

const Footer = styled.footer`
  text-align: center;
  padding: 20px;
  color: white;
`;

const FooterLink = styled.span`
  color: white;
  text-decoration: none;
  margin: 0 10px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Popup = styled.div<{ isOpen: boolean }>`
  display: ${props => props.isOpen ? 'flex' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  max-height: 80%;
  overflow-y: auto;
  color: black;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

export default CoinFlip;